<template>
  <div class="">
    <registroPayForCliente tipo="nuevo" />
  </div>
</template>

<script>
import registroPayForCliente from "@/components/folderBanks/registroPayForCliente";

export default {
  components: {
    registroPayForCliente,
  },
};
</script>

<style></style>
