<template>
  <div>
    <div>
      <listMasterCom></listMasterCom>
    </div>
    <v-navigation-drawer
      v-model="$store.state.master_filtrarData"
      right
      absolute
      bottom
      persistent
      width="20%"
      temporary
    >
      <v-card elevation="0">
        <FiltroMaster />
      </v-card>
    </v-navigation-drawer>
  </div>
</template>
<script>
import listMasterCom from "@/components/folderMaster/listMaster.vue";
import FiltroMaster from "@/components/filtros/FiltroMaster.vue";
export default {
  name: "listEntities",
  components: {
    listMasterCom,
    FiltroMaster
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.state.mainTitle = "LISTA DE EXPEDIENTES";
  },
};
</script>
