<template>
    <div>

        <ControlPaymentFile />

    </div>
</template>

<script>

import ControlPaymentFile from '@/components/folderRequests/controlPaymentFile.vue'
export default {
    name: 'viewControlPaymentFile',
    components: {
        ControlPaymentFile
    }
}
</script>