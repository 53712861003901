<template>
  <v-container fluid>
    <registroPayForCliente tipo="ver" />
  </v-container>
</template>

<script>
import registroPayForCliente from "@/components/folderBanks/registroPayForCliente";

export default {
  components: {
    registroPayForCliente,
  },
};
</script>

<style></style>
