<template>
  <!-- <div class="body"> -->
  <div class="container">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {};
</script>

<style scoped>
* {
  /* background-color: #1a183f; */
  padding: 0;
  margin: 0;
  display: grid;
  place-items: center;
}
.container {
  /* border: 2px solid white; */
  height: 350px;
  width: 350px;
  position: relative;
  animation: spin 1.5s infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.container > div:nth-child(1) {
  height: 75px;
  width: 75px;
  background-image: linear-gradient(45deg, #f7d000, #e6970e);
  position: absolute;
  top: 100px;
  left: 100px;
  box-shadow: 0 0 3x #f7d000;
  border-radius: 50%;
  animation: move1 1.5s infinite;
}
@keyframes move1 {
  50% {
    transform: translate(-30px, -30px) scale(0.3);
  }
}
.container > div:nth-child(2) {
  height: 45px;
  width: 45px;
  background-image: linear-gradient(45deg, #00d7f7, #04c0f5);
  position: absolute;
  top: 105px;
  right: 110px;
  box-shadow: 0 0 2px #00d7f7;
  border-radius: 50%;
  animation: move2 1.5s infinite;
}
@keyframes move2 {
  50% {
    transform: translate(15px -20px) scale(0.55);
  }
}
.container > div:nth-child(3) {
  height: 75px;
  width: 75px;
  background-image: linear-gradient(45deg, #f60180, #f7014f);
  position: absolute;
  bottom: 100px;
  right: 100px;
  box-shadow: 0 0 3px #f60180;
  border-radius: 50%;
  animation: move3 1.5s infinite;
}
@keyframes move3 {
  50% {
    transform: translate(30px, 30px) scale(0.3);
  }
}
.container > div:nth-child(4) {
  height: 45px;
  width: 45px;
  background-image: linear-gradient(45deg, #da00f7, #b501f6);
  position: absolute;
  bottom: 105px;
  left: 110px;
  box-shadow: 0 0 3px #da00f7;
  border-radius: 50%;
  animation: move4 1.5s infinite;
}
@keyframes move4 {
  50% {
    transform: translate(-15px 20px) scale(0.55);
  }
} 
</style>
